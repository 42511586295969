:root {
  --dark-green-1: #003929;
  --dark-green-2: #007251;
  --dark-green-3: #1d503f;
  --green: #00ab79;
  --light-green-1: #00e4a2;
  --light-green-2: #72ffd6;
  --off-white-1: #eeeef7;
  --light-blue-1: var(--off-white-1);
  --dark-blue-1: #3f3d6a;
  --dark-blue-2: #272752;
  --dark-blue-3: #0f133b;
  --light-purple-1: #D4EDF4;
  /* --light-purple-1: #393646; */
  --off-white-1: #eeeef7;
  --grey: #393646;
  --yellow: #f8b507;
  --height-canvas: 65%;
  --color-1: #242424;
  --color-2: #345c72;
  --color-3: #ffffff;
  --color-4: #2a2e30;
  --new-ui-black: #0D1116;
  --new-ui-gray: #161C23;
  --new-ui-border: #222B37;
  --new-ui-border2: #26313F;
  --new-ui-blue: #1053F3;
  --new-ui-black-gradient: linear-gradient(180deg, #161C23 0%, #0D1116 100%);
  --new-ui-black-button-gradient: linear-gradient(180deg, #3D4B5C 0%, #0D1116 100%);
  --new-ui-blue-button-gradient: linear-gradient(180deg, #1053F3 0%, #06256E 100%);
}

.btn:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
}

.btn:active {
  transform: translateY(-1px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}

.fields-background {
  background-color: var(--new-ui-black-button-gradient);
  border: 1px solid var(--new-ui-border);
}

.dashboard-light-no-hover {
  background: #eeeef7;
  background: var(--off-white-1);
  border-radius: 8px;
  /* box-shadow: var(--dark-blue-2) 0 10px 10px -10px; */
  box-sizing: border-box;
  color: #272752;
  color: var(--dark-blue-2);
  cursor: pointer;
  font-size: 12px;
  /* font-weight: 700; */
  line-height: 12px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 4px 4px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  word-break: break-word;
  border: 0;
}

#zmmtg-root {
  display: none;
  background-color: inherit !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: inherit;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--new-ui-border);
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d8d8d8;
}

/*------ React Slider Css------- */
.horizontal-slider {
  width: 100%;
  max-width: 100%;
}

.example-thumb {
  cursor: pointer;
  position: absolute;
  /* z-index: 100; */
  background: #ffffff;
  border: 5px solid var(--color-1);
  border-radius: 100%;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}

.example-thumb.active {
  background-color: grey;
}

.example-track {
  position: relative;
  background: #ddd;
}

.example-track.example-track-0 {
  background: var(--color-1);
}

.horizontal-slider .example-track {
  top: 10px;
  height: 4px;
}

.horizontal-slider .example-thumb {
  top: 7px;
  width: 10px;
  outline: none;
  height: 10px;
  line-height: 38px;
}

/*------ React Slider Css------- */

.button-edit {
  background: var(--new-ui-black-gradient);
  border-radius: 4px;
  padding: 4px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  font-size: 12px;
  opacity: 1;
  outline: 0 solid transparent;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  word-break: break-word;
  border: 0;
}

.button-dark {
  background: var(--dark-blue-2);
  border-radius: 999px;
  box-shadow: var(--dark-blue-2) 0 12px 15px -10px;
  box-sizing: border-box;
  color: var(--off-white-1);
  cursor: pointer;
  font-size: 14px;
  /* font-weight: 700; */
  line-height: 1.5;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 6px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  word-break: break-word;
  border: 0;
}

.button-dark-no-hover {
  background: var(--dark-blue-2);
  border-radius: 999px;
  /* box-shadow: var(--dark-blue-2) 0 10px 10px -10px; */
  box-sizing: border-box;
  color: var(--off-white-1);
  cursor: pointer;
  font-size: 14px;
  /* font-weight: 700; */
  line-height: 1.5;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 6px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  word-break: break-word;
  border: 0;
}

.button-dark-border {
  background: inherit;
  border-radius: 999px;
  /* box-shadow: var(--dark-blue-2) 0 10px 10px -10px; */
  box-sizing: border-box;
  color: var(--dark-blue-2);
  cursor: pointer;
  font-size: 14px;
  /* font-weight: 700; */
  line-height: 1.5;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 6px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  word-break: break-word;
  border: 1px solid var(--dark-blue-2);
}

.button-dark-border:hover {
  transform: scale(1.05);
}

.button-light-border {
  background: inherit;
  border-radius: 999px;
  /* box-shadow: var(--dark-blue-2) 0 10px 10px -10px; */
  box-sizing: border-box;
  color: var(--off-white-1);
  cursor: pointer;
  font-size: 14px;
  /* font-weight: 700; */
  line-height: 1.5;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 6px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  word-break: break-word;
  border: 1px solid var(--off-white-1);
}

.button-light-border:hover {
  transform: scale(1.05);
}

.button-light-no-hover {
  background: var(--off-white-1);
  border-radius: 999px;
  /* box-shadow: var(--dark-blue-2) 0 10px 10px -10px; */
  box-sizing: border-box;
  color: var(--dark-blue-2);
  cursor: pointer;
  font-size: 14px;
  /* font-weight: 700; */
  line-height: 1.5;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 6px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  word-break: break-word;
  border: 0;
}

.button-light-no-hover-subscription {
  background: var(--off-white-1);
  border-radius: 999px;
  /* box-shadow: var(--dark-blue-2) 0 10px 10px -10px; */
  box-sizing: border-box;
  color: var(--dark-blue-2);
  cursor: pointer;
  font-size: 16px;
  /* font-weight: 700; */
  margin-top: 1.7px;
  line-height: 1.8;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 10px 8px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 80%;
  word-break: break-word;
  border: 0;
}

/* CSS */
.button-light {
  background: white;
  border-radius: 999px;
  box-shadow: var(--dark-blue-2) 0 10px 10px -10px;
  box-sizing: border-box;
  color: var(--dark-blue-2);
  cursor: pointer;
  font-size: 14px;
  /* font-weight: 700; */
  line-height: 1.5;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 8px 6px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  word-break: break-word;
  border: 0;
}

.button-dark:hover {
  box-shadow: var(--dark-blue-2) 0 10px 20px -10px;
  transform: scale(1.05);
}

.button-edit:hover {
  transform: scale(1.05);
}

.button-light:hover {
  box-shadow: var(--dark-blue-2) 0 10px 20px -10px;
  transform: scale(1.05);
}

.hover-effect:hover {
  box-shadow: var(--dark-blue-2) 0 10px 20px -10px;
  transform: scale(1.1);
}


.nav-buttons {
  background: var(--off-white-1);
  border-radius: 999px;
  box-shadow: var(--dark-blue-2) 0 10px 10px -10px;
  box-sizing: border-box;
  color: var(--dark-blue-2);
  cursor: pointer;
  opacity: 1;
  outline: 0 solid transparent;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  word-break: break-word;
  border: 0;
}

.nav-buttons:hover {
  box-shadow: var(--dark-blue-2) 0 10px 20px -10px;
  transform: scale(1.05);
}

.icon-padding {
  padding: 7px;
  background: white;
}

@media screen and (min-width: 640px) {
  .dna-popup-width {
    max-width: 80%;
  }

  .right-modal {
    z-index: 999999;
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
  }

  .space-item-responsive {
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    gap: 10px;
  }

  h1 {
    font-family: Roboto;
    font-size: 40px;
  }

  h2 {
    font-family: Roboto;
    font-size: 36px;
  }

  h3 {
    font-family: Roboto;
    font-size: 28px;
  }

  h4 {
    font-family: Roboto;
    font-size: 15px;
    margin: 0;
  }

  html,
  body,
  h5,
  h6 {
    font-family: Roboto;
  }

  body {
    font-family: Roboto;
    font-size: 14px;
  }

  p {
    font-family: Roboto;
    font-size: 14px;
    margin: 0;
  }

  small {
    font-family: Roboto;
    font-size: 12px;
    margin: 0;
  }

  .home-side-button.active {
    background: var(--new-ui-blue-button-gradient);
  }

  .blog-sec-height {
    height: 100vh;
    overflow: auto;
    border-right: 1px solid rgb(229, 234, 239);
  }

  .flex-item {
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  .login-buttons {
    width: 60%;
  }

  .side-panel-1 {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: auto;
    gap: 10px;
  }

  .side-panel-2 {
    display: flex;
    flex-direction: column
  }

  .social-img {
    width: 8%;
  }

  .login-buttons-modal {
    width: 80%;
  }

  .play-music {
    position: absolute;
    top: 12%;
    right: 2%;
  }

  .UploadImg {
    width: 50%;
    margin-top: 7%;
    height: 3vh;
  }

  .loadingPosition {
    position: absolute;
    top: 30%;
    left: 35%;
    width: 70vh;
  }

  .loadingOval {
    margin-left: 46%;
  }

  .joystick {
    position: absolute;
    bottom: 5%;
    right: 10%;
  }

  .bn13 {
    display: inline-block;
    padding: 10px 10px;
    border-radius: 8px;
    box-sizing: border-box;
    text-decoration: none;
    box-shadow: 2px 3px 3px var(--off-white-1);
    color: #eeeef7;
    color: var(--off-white-1);
    background-color: var(--dark-blue-2);
    text-align: center;
    position: absolute;
    top: 85%;
    right: 2%;
  }

  .option_div {
    overflow: hidden;
    position: absolute;
    top: 18%;
    width: 6%;
    right: 2%;
    background-color: inherit;
    display: grid;
    grid-template-rows: auto;
    border-radius: 8px;
  }

  .publish_modal {
    /* position: absolute; */
    border: 1px solid rgb(204, 204, 204);
    background-color: var(--light-blue-1);
    overflow: none;
    border-radius: 25px;
    outline: none;
    padding: 2%;
    width: 35%;
    height: auto;
    box-shadow: var(--dark-blue-2) 0px 6px 17px 3px;
  }

  .premium_modal {
    position: relative;
    border: 1px solid rgb(204, 204, 204);
    background-color: white;
    overflow: none;
    border-radius: 25px;
    outline: none;
    padding: 1%;
    top: 10%;
    left: 10%;
    width: 80%;
    height: 85%;
    box-shadow: var(--dark-blue-2) 0px 6px 17px 3px;
  }

  .premium_inside_modal {
    border: 0px solid rgb(204, 204, 204);
    overflow: none;
    border-radius: 8px;
    outline: none;
    height: 80vh;
  }

  .premium_inner_modal {
    border-radius: 4px;
    background: var(--new-ui-black-gradient);
    border-width: 1px;
    border-style: solid;
    border-color: var(--new-ui-border);
    overflow: none;
    outline: none;
    height: 100%;
  }

  .profile_exp_modal {
    position: relative;
    border: 1px solid rgb(204, 204, 204);
    background-color: white;
    overflow: none;
    border-radius: 25px;
    outline: none;
    /* padding: 1%; */
    top: 23%;
    left: 10%;
    width: 80%;
    height: 50%;
    box-shadow: var(--dark-blue-2) 0px 6px 17px 3px;
  }

  .templates_exp_modal {
    border: 1px solid rgb(204, 204, 204);
    background-color: white;
    overflow: auto;
    border-radius: 16px;
    outline: none;
    padding: 8px;
    top: 13%;
    left: 20%;
    width: 60%;
    height: auto;
    box-shadow: var(--dark-blue-2) 0px 6px 17px 3px;
  }

  .gret-popup {
    position: absolute;
    right: 0px;
    height: 100%;
    box-shadow: var(--new-ui-black) 0px 6px 17px 3px;
    border: none;
    background: var(--new-ui-black);
    color: white;
    overflow: auto;
    outline: none;
    padding: 0px;
    width: 1006px;
  }

  .gret-popup-close {
    position: absolute;
    left: -34px;
    top: 0;
    padding: 8px;
    outline: none;
    border: none;
    cursor: pointer;
  }

  .templates_website_modal2 {
    /* position: absolute; */
    box-shadow: var(--dark-blue-2) 0px 6px 17px 3px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(9, 95, 153);
    background: linear-gradient(0deg, rgba(9, 95, 153, 1) 0%, rgba(90, 183, 246, 1) 100%);
    color: white;
    overflow: auto;
    border-radius: 16px;
    outline: none;
    padding: 0px;
    position: absolute;
    top: 12%;
    left: 7%;
    width: 182vh;
    max-height: 82vh;
    overflow: auto;
    background-color: #F5F5F5;
  }

  .templates_website_modal3 {
    /* position: absolute; */
    box-shadow: var(--dark-blue-2) 0px 6px 17px 3px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(9, 95, 153);
    background: linear-gradient(0deg, rgba(9, 95, 153, 1) 0%, rgba(90, 183, 246, 1) 100%);
    color: white;
    overflow: auto;
    border-radius: 16px;
    outline: none;
    padding: 0px;
    position: absolute;
    top: 12%;
    left: 35%;
    width: 30%;
    max-height: 82vh;
    overflow: auto;
    background-color: #F5F5F5;
  }


  .popup-cards {
    cursor: pointer;
    /* width: 45vh; */
  }

  .comments-section {
    max-height: 32vh;
    overflow: auto;
    /* width: 64vh;  */
  }

  .two-buttons {
    width: 64vh
  }

  .upgrade_modal {
    position: absolute;
    inset: 60px;
    box-shadow: var(--dark-blue-2) 0px 6px 17px 3px;
    border: 1px solid rgb(204, 204, 204);
    background: white;
    color: var(--dark-blue-2);
    overflow: auto;
    border-radius: 16px;
    outline: none;
    padding: 8px;
  }

  .upgarde_plan_name {
    background-color: var(--new-ui-black);
    border-radius: 4px 4px 0 0;
    color: white;
    padding: 16px;
    font-size: 25px;
  }

  .error_modal {
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background-color: var(--light-blue-1);
    overflow: none;
    border-radius: 25px;
    outline: none;
    padding: 1%;
    top: 35%;
    left: 40%;
    width: 20%;
    height: 20%;
    box-shadow: var(--dark-blue-2) 0px 6px 17px 3px;
  }

  .calender_modal {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    position: absolute;
    border-radius: 25px;
    box-shadow: var(--new-ui-black) 0px 6px 17px 3px;
  }

  .login_modal {
    position: absolute;
    background-color: var(--new-ui-black);
    overflow: none;
    border-radius: 4px;
    outline: none;
    padding: 1%;
    top: 20%;
    left: 35%;
    width: 30%;
    height: auto;
    box-shadow: var(--new-ui-black) 0px 6px 17px 3px;
  }

  .picture_Upload_modal {
    border: 1px solid rgb(204, 204, 204);
    background-color: var(--light-blue-1);
    overflow: none;
    border-radius: 25px;
    outline: none;
    padding: 16px;
    position: relative;
    width: 30%;
    height: auto;
    z-index: 110;
    box-shadow: var(--dark-blue-2) 0px 6px 17px 3px;
  }

  .caption {
    position: absolute;
    bottom: 15%;
    right: 35%;
    text-align: center;
    width: 30%;
    font-size: 20px;
  }

  .caption_input::placeholder {
    color: black;
    opacity: 1;
    /* Firefox */
  }

  .caption_input {
    padding: 8px;
    display: block;
    border: none;
    width: 100%;
    color: black;
    background-color: inherit;
    border-radius: 8px;
    text-align: center;
    font-size: 25px;
  }

  .navigation_button {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    width: 82%;
    margin-left: 4%;
    grid-column-gap: 4%;
    -webkit-column-gap: 4%;
    column-gap: 4%;
    grid-row-gap: 8%;
    row-gap: 8%;
    font-size: 8px;
  }

  .top_bar {
    display: flex;
  }

  .container3d canvas {
    width: 100% !important;
    height: 100% !important;
  }

  .main-scene canvas {
    float: right;
    width: 60% !important;
    height: 100% !important;
  }

  .margin-loader {
    height: 100%;
    width: 60%;
  }

  .canvas-loader {
    position: absolute;
    right: 0;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: var(--new-ui-black);
    color: white;
    z-index: 9999;
  }

  .profile_modal {
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background-color: var(--light-blue-1);
    overflow: auto;
    border-radius: 25px;
    outline: none;
    padding: 2%;
    top: 25%;
    left: 40%;
    width: 20%;
    height: 50%;
    z-index: 110;
    box-shadow: var(--dark-blue-2) 0px 6px 17px 3px;
  }

  .publishShareModal {
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background-color: var(--off-white-1);
    border-radius: 25px;
    outline: none;
    padding: 1%;
    top: 35%;
    left: 40%;
    width: 15%;
    height: 35%;
    box-shadow: var(--dark-blue-2) 0px 6px 17px 3px;
  }

  .save_modal {
    background-color: var(--new-ui-gray);
    overflow: none;
    border-radius: 8px;
    outline: none;
    width: 35%;
    height: auto;
    z-index: 110;
    box-shadow: var(--new-ui-black) 0px 6px 17px 3px;
  }

  .create_avatar_modal {
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background-color: var(--off-white-1);
    background-color: var(--light-blue-1);
    overflow: none;
    border-radius: 25px;
    outline: none;
    top: 9%;
    left: 20%;
    width: 60%;
    height: 82vh;
    z-index: 110;
    box-shadow: var(--dark-blue-2) 0px 6px 17px 3px;
  }

  .loading_modal {
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background-color: var(--off-white-1);
    overflow: auto;
    outline: none;
    padding: 2%;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    z-index: 110;
  }

  .side-panel-layout {
    float: left;
    width: 30%;
  }

  .panel {
    width: 100%;
    /* height: 100vh; */
    display: flex;
    flex-direction: row;
    transition: 0.5s;
  }

  .vr-panel {
    width: 40%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    transition: 0.5s;
    border-right: 1px solid var(--new-ui-border2);
  }

  .left_section {
    height: 100vh;
  }

  .right_section {
    height: 100vh;
  }

  .side_options {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 30%;
    z-index: 991;
    height: 0px;
    margin-top: 45px;
    margin-right: 4px;
    right: 0px;
    max-height: calc(-63px + 100vh);
    min-height: 15%;
    max-width: calc(-24px + 100vw);
    visibility: hidden;
    background-color: white;
    font-size: 20px;
  }

  .side_options_handler_open {
    position: fixed;
    z-index: 1;
    right: 0%;
    top: 0%;
    background-color: var(--color-1);
    /* border-radius: 8px 0px 0px 8px; */
    min-height: 100%;
    max-height: 76vh;
    width: 15%;
    overflow: auto;
    animation: 1s in-out forwards;
  }

  @keyframes in-out {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .side_options_handler_close {
    position: fixed;
    z-index: 1;
    right: 0%;
    top: 15%;
    height: 50vh;
    background-color: var(--color-1);
    border-radius: 25px 0px 0px 25px;
    padding: 4px 12px;
  }

  .search_div {
    color: var(--dark-blue-2);
    padding: 16px;
  }

  .publish_text {
    right: -3% !important;
    text-decoration: none !important;
    top: 15% !important;
  }

  .showFormModal {
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background-color: var(--off-white-1);
    overflow: none;
    border-radius: 25px;
    outline: none;
    padding: 1%;
    top: 25%;
    left: 30%;
    width: 40%;
    height: 45%;
    z-index: 110;
    box-shadow: var(--dark-blue-2) 0px 6px 17px 3px;
  }

  .showFormModal2 {
    position: absolute;
    border: 1px solid var(--new-ui-border2);
    background-color: var(--new-ui-black);
    color: white;
    overflow: none;
    border-radius: 8px;
    outline: none;
    top: 25%;
    left: 30%;
    width: 40%;
    max-height: 62%;
    overflow: auto;
    z-index: 110;
    box-shadow: var(--new-ui-black) 0px 6px 17px 3px;
  }

  .settingsModal {
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background-color: var(--off-white-1);
    overflow: auto;
    border-radius: 20px;
    outline: none;
    padding: 8px 16px;
    top: 7%;
    right: 16px;
    width: 25%;
    min-height: 40%;
    max-height: 70%;
    box-shadow: var(--dark-blue-2) 0px 6px 17px 3px;
  }

  .uploadFormStyle {
    display: grid;
    grid-template-columns: 25% 75%;
    grid-template-rows: 50% 50%;
  }
}

video::-webkit-media-controls-fullscreen-button {
  display: none;
}

.upload_section {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 90%;
  grid-column-gap: 8%;
  row-gap: 20%;
}

.upload_cards {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 85%;
  margin-left: 4%;
  padding-top: 1%;
  grid-column-gap: 8%;
  column-gap: 8%;
}

.upload_buttons {
  background-color: white;
  color: var(--dark-blue-2);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  padding: 6%;
}

.propPopup {
  display: grid;
  grid-template-rows: auto;
  width: 100%;
  height: 100%;
}

.propPopup p {
  margin: 0%;
  padding-left: 2%;
}

.dashboard_layout {
  display: grid;
  grid-template-columns: 10% 70% 20%;
  /*height: 100vh*/
}

.create_card {
  cursor: pointer;
  display: grid;
  background-color: var(--off-white-1);
  width: 70%;
  margin-left: 15%;
  text-align: center;
  border-radius: 8px;
}

.create_card a {
  text-decoration: none;
}

.create_img {
  width: 100%;
  height: 25vh;
}

.positionPublish {
  position: absolute;
  width: 100%;
  top: 0%;
  margin: 0%;
}

@media screen and (max-width: 600px) {
  .popup-eng {
    margin-left: 11px;
  }

  h1 {
    font-family: "Exo", "fira sans";
    font-size: 26px !important;
  }

  h2 {
    font-family: "Exo", "fira sans";
    font-size: 22px;
  }

  h3 {
    font-family: "Exo", "fira sans";
    font-size: 18px;
  }

  h4 {
    font-family: "Exo", "fira sans";
    font-size: 14px;
    margin: 0;
  }

  html,
  body,
  h5,
  h6 {
    font-family: "fira sans";
  }

  body {
    font-family: "fira sans";
    font-size: 12px;
  }

  p {
    font-family: "fira sans";
    font-size: 12px;
    margin: 0;
  }

  small {
    font-family: "fira sans";
    font-size: 10px;
    margin: 0;
  }

  .toolset-banner {
    font-size: 22px;
  }

  .home-side-button.active {
    border-radius: 4px !important;
  }

  .blog-sec-height {
    height: 25vh;
    overflow: auto;
    border-right: 1px solid rgb(229, 234, 239);
  }

  .flex-item {
    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }

  .login-buttons {
    width: 100%;
  }

  .side-panel-1 {
    display: flex;
    flex-direction: row;
    width: 100vw;
    overflow: auto;
    background: var(--new-ui-black);
  }

  .side-panel-2 {
    display: flex;
    flex-direction: row;
  }

  .social-img {
    width: 15%;
  }

  .login-buttons-modal {
    width: 100%;
  }

  .play-publish {
    position: absolute;
    top: 14vh;
    right: 2%;
    width: auto;
    padding: 4px;
  }

  .play-music {
    position: absolute;
    top: 5%;
  }

  .settingsModal {
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background-color: var(--off-white-1);
    overflow: auto;
    border-radius: 16px;
    outline: none;
    padding: 8px 16px;
    top: 13%;
    right: 5%;
    width: 90%;
    height: auto;
    max-height: 70%;
    box-shadow: var(--dark-blue-2) 0px 6px 17px 3px;
  }

  .gretLogo {
    width: 100%;
    height: 8vh;
  }

  .create {
    display: grid;
    grid-template-columns: 15% 23% 15% auto;
  }

  .create_button {
    width: 90%;
    margin: 5%;
    padding: 12px 2%;
  }

  .create h1 {
    margin-bottom: 0;
    margin-left: "15%";
  }

  .sceneoptions {
    border: none;
    height: 60vh;
  }

  .dashboard_icons_1 {
    width: 100%;
    margin-left: 0%;
    margin-top: 45%;
  }

  .dashboard_icons {
    width: 40px;
    position: absolute;
    left: 0%;
  }

  .EnviroImg {
    width: 35%;
    margin-top: 7%;
  }

  .PropImg {
    width: 55%;
    margin-top: 7%;
  }

  .AvatarImg {
    width: 35%;
    margin-top: 7%;
  }

  .UploadImg {
    width: 50%;
    margin-top: 7%;
    height: 3vh;
  }

  .TextImg {
    width: 40%;
    margin-top: 7%;
  }

  .fashion {
    width: 27%;
    margin-top: 7%;
  }

  .loadingPosition {
    position: absolute;
    top: 30%;
    width: 100%;
  }

  .loadingOval {
    margin-left: 35%;
  }

  .loading_modal_position {
    position: absolute;
    top: 33%;
    right: 35%;
  }

  .formButton {
    width: 15% !important;
    right: 0% !important;
  }

  .joystick {
    position: absolute;
    bottom: 5%;
    right: 10%;
    z-index: 1;
  }

  .positionAddScene {
    position: absolute;
    top: 2%;
    right: 10%;
  }

  .option_div {
    overflow: hidden;
    position: absolute;
    top: 15%;
    width: 15%;
    right: 2%;
    background-color: inherit;
    display: grid;
    grid-template-rows: auto;
    border-radius: 8px;
  }

  .create_avatar_modal {
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background-color: var(--light-blue-1);
    overflow: none;
    border-radius: 25px;
    outline: none;
    top: 15%;
    left: 2%;
    width: 54vh;
    height: 80vh;
    z-index: 110;
    box-shadow: var(--dark-blue-2) 0px 6px 17px 3px;
  }

  .publishShareModal {
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background-color: var(--off-white-1);
    border-radius: 25px;
    outline: none;
    padding: 2%;
    top: 35%;
    left: 25%;
    width: 50%;
    height: 30%;
    box-shadow: var(--dark-blue-2) 0px 6px 17px 3px;
  }

  .publish_modal {
    /* position: absolute; */
    border: 1px solid rgb(204, 204, 204);
    background-color: var(--light-blue-1);
    overflow: none;
    border-radius: 25px;
    outline: none;
    padding: 2%;
    border-radius: 25px;
    width: 80%;
    height: auto;
    box-shadow: var(--dark-blue-2) 0px 6px 17px 3px;
  }

  .premium_modal {
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background-color: white;
    overflow: none;
    border-radius: 25px;
    outline: none;
    padding: 1%;
    top: 15%;
    left: 10%;
    width: 80%;
    height: 75%;
    box-shadow: var(--dark-blue-2) 0px 6px 17px 3px;
    padding: 4px 0px 15px 12px !important;
  }

  .premium_inside_modal {
    border: 0px solid rgb(204, 204, 204);
    overflow: auto;
    border-radius: 25px;
    outline: none;
    height: 85%;
    padding: 2px !important;
  }

  .premium_inner_modal {
    border-radius: 4px;
    background: var(--new-ui-black-gradient);
    border-width: 1px;
    border-style: solid;
    border-color: var(--new-ui-border);
    overflow: none;
    outline: none;
    height: 100%;
  }

  .templates_exp_modal {
    border: 1px solid rgb(204, 204, 204);
    background-color: white;
    overflow: auto;
    border-radius: 16px;
    outline: none;
    padding: 8px;
    top: 13%;
    left: 5%;
    width: 90%;
    height: auto;
    box-shadow: var(--dark-blue-2) 0px 6px 17px 3px;
  }

  .gret-popup {
    position: absolute;
    inset: 20px;
    box-shadow: var(--new-ui-black) 0px 6px 17px 3px;
    border: none;
    background: var(--new-ui-black);
    color: white;
    overflow: auto;
    height: 80vh;
    border-radius: 4px;
    outline: none;
    padding: 0px;
  }

  .templates_website_modal2 {
    position: absolute;
    box-shadow: var(--dark-blue-2) 0px 6px 17px 3px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(9, 95, 153);
    background: linear-gradient(0deg, rgba(9, 95, 153, 1) 0%, rgba(90, 183, 246, 1) 100%);
    color: white;
    overflow: auto;
    max-height: 80vh;
    border-radius: 16px;
    outline: none;
    padding: 0px;
    top: 10px;
    left: 10px;
    width: 90vw;
  }

  .templates_website_modal3 {
    position: absolute;
    box-shadow: var(--dark-blue-2) 0px 6px 17px 3px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(9, 95, 153);
    background: linear-gradient(0deg, rgba(9, 95, 153, 1) 0%, rgba(90, 183, 246, 1) 100%);
    color: white;
    overflow: auto;
    max-height: 80vh;
    border-radius: 16px;
    outline: none;
    padding: 0px;
    top: 5%;
    left: 5%;
    width: 90%;
  }

  .popup-cards {
    cursor: pointer;
    /* width: 40vh !important; */
    /* margin-inline: 22px !important; */
  }

  .comments-section {
    max-height: 28vh;
    overflow: auto;
    /* width: 50vh;  */
  }

  .two-buttons {
    width: 40vh
  }

  .upgrade_modal {
    position: absolute;
    inset: 20px;
    box-shadow: var(--dark-blue-2) 0px 6px 17px 3px;
    border: 1px solid rgb(204, 204, 204);
    background: white;
    color: var(--dark-blue-2);
    overflow: auto;
    border-radius: 16px;
    outline: none;
    padding: 8px;
  }

  .upgarde_plan_name {
    background-color: var(--new-ui-black);
    border-radius: 4px 4px 0 0;
    color: white;
    font-weight: bolder;
    padding: 16px;
    font-size: 25px;
  }

  .error_modal {
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background-color: var(--light-blue-1);
    overflow: none;
    border-radius: 25px;
    outline: none;
    padding: 1%;
    top: 11%;
    left: 5%;
    width: 80%;
    height: 20%;
    box-shadow: var(--dark-blue-2) 0px 6px 17px 3px;
  }

  .login_modal {
    position: absolute;
    background-color: var(--new-ui-black);
    overflow: none;
    border-radius: 4px;
    outline: none;
    padding: 1%;
    top: 12%;
    left: 5%;
    width: 90%;
    height: auto;
    box-shadow: var(--new-ui-black) 0px 6px 17px 3px;
    z-index: 9999;
  }

  .showFormModal {
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background-color: var(--light-blue-1);
    overflow: none;
    border-radius: 25px;
    outline: none;
    padding: 1%;
    top: 5%;
    left: 2%;
    width: 80%;
    height: 73%;
    z-index: 110;
    box-shadow: var(--dark-blue-2) 0px 6px 17px 3px;
  }

  .showFormModal2 {
    position: absolute;
    border: 1px solid var(--new-ui-border2);
    background-color: var(--new-ui-black);
    color: white;
    overflow: none;
    border-radius: 8px;
    outline: none;
    padding: 1%;
    top: 5%;
    left: 6%;
    width: 88%;
    max-height: 92%;
    overflow: auto;
    z-index: 110;
    box-shadow: var(--new-ui-black) 0px 6px 17px 3px;
  }

  .picture_Upload_modal {
    border: 1px solid rgb(204, 204, 204);
    background-color: var(--light-blue-1);
    overflow: none;
    border-radius: 25px;
    outline: none;
    padding: 16px;
    width: 80%;
    height: auto;
    z-index: 110;
    box-shadow: var(--dark-blue-2) 0px 6px 17px 3px;
  }

  .caption {
    position: absolute;
    bottom: 40%;
    text-align: center;
    width: 100%;
    font-size: 20px;
  }

  .caption_input {
    padding: 8px;
    display: block;
    border: none;
    width: 54%;
    color: black;
    background-color: inherit;
    border-radius: 8px;
    margin-left: 23%;
    text-align: center;
    font-size: 25px;
  }

  .publish_text {
    right: 6% !important;
    text-decoration: none !important;
    top: 19% !important;
  }

  .search_div {
    color: var(--dark-blue-2);
    padding: 16px;
  }

  .logo {
    width: 100%;
    margin-left: -7%;
    margin-top: 16%;
  }

  .top_bar {
    display: flex;
  }

  .navigation_button {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    width: 85%;
    margin-left: 3%;
    grid-column-gap: 4%;
    -webkit-column-gap: 4%;
    grid-column-gap: 4%;
    column-gap: 4%;
    grid-row-gap: 8%;
    row-gap: 8%;
    font-size: 8px;
  }

  .panel {
    width: 100%;
    height: 100vh;
    overflow: auto;
    z-index: 99;
  }

  .vr-panel {
    position: absolute;
    z-index: 0;
    border-top: 4px solid var(--new-ui-border2);
    color: var(--dark-blue-2);
    bottom: 0%;
    height: 35%;
    width: 100%;
    background-color: var(--off-white-1);
    overflow: auto;
  }

  .search_div {
    margin-top: 0%;
  }

  .container3dPublish canvas {
    top: 0%;
    width: 100% !important;
    height: 100% !important;
  }

  .container3d canvas {
    position: absolute;
    top: 0%;
    width: 100% !important;
    height: var(--height-canvas) !important;
  }

  .main-scene canvas {
    position: absolute;
    top: 0%;
    width: 100% !important;
    height: var(--height-canvas) !important;
  }

  .canvas-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: var(--new-ui-black);
    color: white;
    z-index: 9999;
  }

  .side_options {
    overflow: hidden;
    position: absolute;
    top: 0px;
    width: 30%;
    z-index: 991;
    height: 0px;
    margin-top: 45px;
    margin-right: 4px;
    right: 0px;
    max-height: calc(-63px + 100vh);
    min-height: 40%;
    max-width: calc(-24px + 100vw);
    visibility: hidden;
    background-color: white;
    font-size: 20px;
  }

  .side_options_handler_open {
    position: relative;
    z-index: 1;
    width: 100%;
    /* right: 0%; */
    bottom: 0%;
    background-color: var(--color-1);
    /* border-radius: 25px 0px 0px 25px; */
  }

  .side_options_handler_close {
    position: fixed;
    z-index: 1;
    right: 0%;
    top: 15%;
    height: 35vh;
    background-color: var(--color-1);
    border-radius: 25px 0px 0px 25px;
    padding: 4px 12px;
  }

  .left_section {
    height: 50vh;
  }

  .right_section {
    height: 50vh;
  }

  .position_loader {
    position: absolute;
    top: 45%;
    margin-left: 0%;
    left: 0%;
  }

  .position_loader_text {
    position: absolute;
    top: 40%;
    margin-left: 0%;
    right: 15%;
  }

  .icons_style {
    width: 68% !important;
  }

  .profile_modal {
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background-color: var(--light-blue-1);
    overflow: auto;
    border-radius: 25px;
    outline: none;
    padding: 2%;
    top: 8%;
    left: 15%;
    width: 70%;
    height: 41%;
    z-index: 110;
  }

  .save_modal {
    border: 1px solid rgb(204, 204, 204);
    background-color: var(--new-ui-gray);
    overflow: none;
    border-radius: 8px;
    outline: none;
    width: 90%;
    z-index: 110;
  }

  .video_modal {
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background-color: var(--light-blue-1);
    overflow: none;
    border-radius: 25px;
    outline: none;
    padding: 2%;
    top: 3%;
    left: 10%;
    width: 80%;
    height: 60%;
    z-index: 110;
  }

  .loading_modal {
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background-color: var(--off-white-1);
    overflow: auto;
    outline: none;
    padding: 2%;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    z-index: 200;
  }

  .uploadFormStyle {
    display: flex;
    flex-direction: column;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 16px;
  }

  h5 {
    font-size: 14px;
  }

  h6 {
    font-size: 12px;
  }
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.option_button {
  width: 100%;
  border: none;
  outline: none;
  background-color: inherit;
  cursor: pointer;
  padding: 5%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  --webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  --moz-appearance: textfield;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.button_div {
  color: var(--dark-blue-2);
}

.button_div button {
  position: absolute;
  left: 69%;
  padding: 9px 3%;
  width: 15%;
}

.color_input {
  display: block;
  width: 75%;
  border-radius: 2px;
}

.color_input::-webkit-color-swatch {
  border: none;
  border-radius: 2px;
  padding: 0;
}

.model_cards {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  grid-column-gap: 0%;
  column-gap: 0%;
  padding: 1%;
  border-top: 4px solid white;
}

.model_image {
  margin-left: 0%;
  cursor: pointer;
  object-fit: cover;
  height: 15vh;
  border-radius: 3px 3px 0px 0px;
  width: 100%;
}

.model-title {
  border-radius: 0px 0px 3px 3px;
  line-height: 1.3em;
  min-height: 3em;
  /* Ensures height for at least two lines */
  max-height: 3em;
  /* Limits height to two lines */
  overflow: hidden;
  display: -webkit-box;
  /* Enables truncation for multiline text */
  -webkit-line-clamp: 2;
  /* Limits text to 2 lines */
  -webkit-box-orient: vertical;
  /* Sets the orientation to vertical for truncation */
  word-wrap: break-word;
  /* Ensures long words break to fit within the container */
}

.options button {
  position: absolute;
}

.options:hover button {
  position: absolute;
}

.nav_button {
  background: inherit;
  border-radius: 4px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: 0;
  padding: 4px;
}

.nav_button:hover {
  background-color: var(--new-ui-blue);
}

.nav_button.active {
  background-color: var(--new-ui-blue);
}

.site_button {
  color: var(--dark-blue-2);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
}

.contactUsStyle {
  right: 0.75%;
  text-decoration: none;
  top: 68%;
  font-size: 24px;
}

.pos_button {
  margin-left: 90%;
}

.sidebar_button {
  margin-left: 0%;
  background-color: inherit;
  border: none;
  color: var(--light-green-1);
  cursor: pointer;
}

.search_outer {
  /* background-color: var(--light-green-1); */
  /* padding: 4%; */
  border-radius: 30px;
}

.search_inner {
  border-radius: 30px;
  width: 100%;
  border: none;
  color: var(--dark-blue-2);
  padding-left: 10%;
}

.search_outer i {
  position: absolute;
  top: 1px;
}

.search_outer img {
  position: absolute;
}

.icon {
  padding: 1%;
  padding-left: 1%;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.options {
  display: grid;
  border: 1px solid var(--new-ui-border2);
  text-align: center;
  width: 100%;
  background: var(--new-ui-black);
  box-shadow: none;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  line-height: 18px;
  opacity: 1;
  outline: 0 solid transparent;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  text-decoration: none;
}

.options:hover {
  border: 1px solid var(--new-ui-blue)
}

code,
kbd,
pre,
samp {
  font-family: "fira sans";
  font-size: 15px;
  text-wrap: wrap;
}

.padding-side-small {
  padding: 2px 8px;
}

.padding-vertical-small {
  padding: 5px 0;
}

.custom-file-input:hover {
  box-shadow: var(--new-ui-border) 0 10px 20px -10px;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  background: var(--new-ui-black-button-gradient);
  border-radius: 4px;
  box-shadow: var(--off-white-1) 0 10px 10px -10px;
  box-sizing: border-box;
  color: var(--off-white-1);
  display: inline-block;
  padding: 8px 6px;
  text-align: center;
  outline: none;
  white-space: nowrap;
  content: "Select File";
  cursor: pointer;
  color: var(--off-white-1);
  width: 100%;
}

.file-input-dark::-webkit-file-upload-button {
  background: var(--dark-blue-2) !important;
  color: white !important;
}

.file-input-black::-webkit-file-upload-button {
  background: black !important;
  color: white !important;
  display: inline-block;
  background: white;
  outline: none;
  border: none;
  font-size: 12px;
  border-radius: 8px;
  padding: 8px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
}

.file-input::-webkit-file-upload-button {
  display: inline-block;
  background: white;
  outline: none;
  border: none;
  font-size: 12px;
  border-radius: 8px;
  padding: 2%;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  /* height: 100%; */
  color: var(--dark-blue-2);
  width: 50%;
}

.file-input-grey::-webkit-file-upload-button {
  display: inline-block;
  background: var(--off-white-1);
  outline: none;
  border: none;
  font-size: 12px;
  border-radius: 8px;
  padding: 2%;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  /* height: 100%; */
  color: black;
  width: 50%;
}

.file-input:hover::before {
  border-color: var(--off-white-1);
}

.custom-file-input:hover::before {
  border-color: var(--off-white-1);
}

.fileButton {
  background-color: indigo;
  color: white;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 1rem;
}

#file-chosen {
  margin-left: 0.3rem;
  font-family: sans-serif;
}

.helper {
  line-height: 1.3;
  color: var(--off-white-1);
  opacity: 0.9;
}

.createPage {
  display: block;
}

.createPage h1 {
  font-size: 28px;
}

.createPage h2 {
  font-size: 23px;
}

.createPage h3 {
  font-size: 18px;
}

.createPage p {
  font-family: "fira sans";
  font-size: 14px;
}

.dashboard_layout h1 {
  font-size: 28px;
}

.dashboard_layout h2 {
  font-size: 23px;
}

.dashboard_layout h3 {
  font-size: 18px;
}

.dashboard_layout p {
  font-family: "fira sans";
  font-size: 14px;
}

.left_section h1 {
  font-size: 28px;
}

.left_section h2 {
  font-size: 23px;
}

.left_section h3 {
  font-size: 18px;
}

.left_section p {
  font-family: "fira sans";
  font-size: 14px;
}

.right_section h1 {
  font-size: 28px;
}

.right_section h2 {
  font-size: 23px;
}

.right_section h3 {
  font-size: 18px;
}

.right_section p {
  font-family: "fira sans";
  font-size: 14px;
}

.editButton {
  cursor: pointer;
  background: inherit;
  margin: 0%;
  padding: 0%;
  outline: none;
  border: none;
}

.VrCards::-webkit-scrollbar {
  height: 8px;
}

/* Track */
.VrCards::-webkit-scrollbar-track {
  background: #d8d8d8;
}

/* Handle */
.VrCards::-webkit-scrollbar-thumb {
  background: var(--dark-blue-2);
}

.container {
  width: 90%;
  margin: auto;
}

.cards-container {
  display: grid;
  padding: 8%;
  row-gap: 4%;
  /* border-radius: 8px 8px 0px 0px; */
  color: #272752;
  color: var(--dark-blue-2);
  background: inherit;
  /* padding: 1rem; */
  /* box-shadow: 0 0 1rem var(--dark-blue-2) inset; */
  overflow: auto;
  position: relative;
  max-height: 40vh;
}

.cards-container::-webkit-scrollbar {
  display: none;
}

.card {
  box-shadow: 0 1px 1rem -4px #000;
  background: #fff;
  border-radius: 8px;
  cursor: pointer;
  transition: all 250ms ease-in-out;
}

.card img {
  width: 100%;
  object-fit: fill;
}

.card:hover {
  box-shadow: 0 4px 1rem -4px #000;
  transform: translateY(-8px);
}

@media screen and (max-width: 625px) {
  h1 {
    font-family: "Exo", "fira sans";
    font-size: 36px !important;
  }

  h2 {
    font-family: "Exo", "fira sans";
    font-size: 22px;
  }

  h3 {
    font-family: "Exo", "fira sans";
    font-size: 18px;
  }

  h4 {
    font-family: "Exo", "fira sans";
    font-size: 14px;
    margin: 0;
  }

  html,
  body,
  h5,
  h6 {
    font-family: "fira sans";
  }

  .toolset-banner {
    font-size: 22px;
  }

  body {
    font-family: "fira sans";
    font-size: 12px;
  }

  p {
    font-family: "fira sans";
    font-size: 12px;
    margin: 0;
  }

  small {
    font-family: "fira sans";
    font-size: 10px;
    margin: 0;
  }

  .scenes_flex_grid {
    display: grid;
    grid-template-columns: 10% 80% 10%;
    width: 56vh;
  }

  .container {
    width: 100%;
  }

  .cards-container {
    padding: 0.5rem;
  }

  .card {
    margin: 0.5rem;
  }
}

input[type="color"] {
  border-radius: 4px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
}

.loading_modal_content {
  /* background-color: white; */
  border-radius: 20px;
  padding: 9%;
}

.link_button {
  text-decoration: none;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  color: var(--dark-blue-2);
  padding: 0%;
}

.transparent-button {
  cursor: pointer;
  border: none;
  background: inherit;
  cursor: pointer;
  padding: 0px;
}

.transparent-button:disabled {
  background: inherit;
  color: #cccccc;
}

.hover-transform:hover {
  /* transform: scale(1.05); */
  background-color: var(--off-white-1);
}

.hover-highlight {
  opacity: 0.3;
  transition: background-color 1s;
}

.hover-highlight:hover {
  opacity: 1;
}

.gret-button {
  border: none;
  display: inline-block;
  padding: 8px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
}

.signup_input {
  background-color: white;
  border-color: rgb(223, 225, 230);
  color: rgb(9, 30, 66);
  cursor: text;
  border-radius: 8px;
  border-width: 2px;
  border-style: solid;
  box-sizing: border-box;
  font-size: 14px;
  transition: background-color 0.2s ease-in-out 0s,
    border-color 0.2s ease-in-out 0s;
  line-height: 1.5;
  padding: 8px 6px;
  height: 36px;
}

.signup_input:focus {
  color: var(--dark-blue-2);
  background-color: rgb(255, 255, 255);
  border-color: rgb(76, 154, 255);
}

.signup_input:hover {
  outline: 1px solid #ccc;
}

.signup_input:disabled {
  background-color: #ccc;
}

.signup_input option {
  font-size: 16px;
}

.input-light {
  padding: 4px;
  display: block;
  border: 1px solid white;
  color: var(--dark-blue-2);
  border-radius: 8px;
  font-size: 16px;
}

.video_button {
  display: inline-block;
  padding: 5px;
  margin-left: 1%;
  color: var(--off-white-1);
  background-color: inherit;
  width: 3%;
  outline: none;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 8px;
}

.signup_button {
  display: inline-block;
  padding: 8px 1%;
  margin-left: 1%;
  color: var(--off-white-1);
  background-color: var(--dark-blue-2);
  width: 3%;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 8px;
}

.gret-input {
  display: inline-block;
  padding: 4px 1%;
  color: var(--dark-blue-2);
  background-color: white;
  outline: 1px solid #ccc;
  border: none;
  border-radius: 4px;
}

.gret-input:focus {
  outline: 1px solid var(--dark-blue-2);
}

.gret-input:hover {
  outline: 1px solid var(--dark-blue-2);
}

button:disabled,
button[disabled] {
  background-color: #cccccc;
  color: #666666;
}

.signup_checkbox {
  height: 16px;
  width: 16px;
  cursor: pointer;
}

hr {
  border-top: 1px solid var(--new-ui-border2);
}

#div1 {
  width: 100%;
  height: 100%;
  padding: 10px;
}

/* Quest check box css starts_________________________________________________________ */
.wrapper {
  width: 100%;
  margin-top: 2%;
  margin-left: 7%;
}

.container {
  display: grid;
  justify-content: space-evenly;
  gap: 15% 0%;
  grid-template-columns: 50% 50%;
  padding-left: 10%;
}

.option_item {
  position: relative;
  width: 80%;
}

.container .option_item .checkbox {
  position: absolute;
  opacity: 0;
}

.option_item .option_inner {
  width: 100%;
  background: var(--light-green-2);
  border-radius: 8px;
  text-align: center;
  box-shadow: 1px 2px var(--off-white-1);
  cursor: pointer;
  color: var(--dark-blue-1);
  display: block;
  border: 3px solid transparent;
  position: relative;
  padding-bottom: 3%;
  padding-top: 3%;
}

.option_item .option_inner .name {
  margin-top: 1%;
  padding-top: 2%;
}

.checkbox:checked~.option_inner.facebook {
  border-color: var(--off-white-1);
  color: var(--dark-blue-2);
}

.tickmark {
  position: relative;
  top: 23%;
  left: 40%;
  display: none;
}

.tickmark:before {
  content: "";
  position: absolute;
  width: 31px;
  height: 15px;
  border: 6px solid;
  border-color: transparent transparent var(--dark-green-2) var(--dark-green-2);
  transform: rotate(-45deg);
}

.level_tickmark {
  content: "";
  position: relative;
  width: 31px;
  height: 15px;
  border: 6px solid;
  border-color: transparent transparent var(--dark-green-2) var(--dark-green-2);
  transform: rotate(-45deg);
}

.option_item .checkbox:checked~.option_inner .tickmark {
  display: block;
}

.tickmark {
  border-color: transparent transparent transparent transparent;
}

/* Quest checkbox css ends __________________________________________________*/

.dashboard_layout {
  display: grid;
  grid-template-columns: 10% 70% 20%;
  height: 100vh;
}

.dashboard_icons {
  width: 4%;
  position: absolute;
  left: 3%;
}

.design_button {
  display: inline-block;
  padding: 8px 3%;
  color: var(--off-white-1);
  background-color: var(--dark-green-1);
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 8px;
}

.no-margin {
  margin: 0 !important;
}

.center-item {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.center-item-row {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.space-item {
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.wrap-item {
  flex-wrap: wrap;
}

.left-item {
  justify-content: left;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

#brandlogo {
  position: fixed;
  width: 100%;
  height: 100vh;
  display: block;
  top: 0;
  left: 0;
  z-index: -9999;
}

#reveal-effect {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  /* transition: all 5s; */
}

.gret-input-line {
  background-color: inherit;
  border: none;
  border-bottom-color: var(--dark-blue-2);
  border-bottom-style: groove;
}

.gret-input-line[type="text"]:focus {
  background-color: inherit;
  border: none;
  border-bottom-color: var(--dark-blue-4);
  border-bottom-style: groove;
  outline: none;
  color: var(--dark-gray-1);
}

.navigation-padding {
  padding: 2px 2px;
}

.navigation-padding-large {
  padding: 1px 8px 4px 0px;
}

.settings-padding {
  padding: 4px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.video-container {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: "";
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.CircularProgressbar-path {
  stroke: var(--dark-blue-2) !important;
}

.CircularProgressbar-trail {
  stroke: var(--dark-blue-2);
}

.CircularProgressbar-text {
  fill: white;
}

.CircularProgressbar-background {
  fill: var(--dark-blue-2);
}

.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
  padding-inline-start: 0px;
}

.pagination a {
  font-size: 15px;
  padding: 2px 6px;
  border-radius: 5px;
  border: 1px solid var(--new-ui-border);
  color: white;
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color: #fff;
  background: var(--new-ui-blue);
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}

.dashboard_table {
  border-radius: 4px;
  overflow: hidden;
}

.dashboard_table thead tr {
  background-color: var(--new-ui-black) !important;
  color: white !important;
}

.dashboard_table tbody tr {
  background-color: inherit !important;
  color: white !important;
}


table tr {
  font-weight: normal;
}

table tr.selected {
  font-weight: bolder;
}

.nav-icons-gret {
  min-height: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3vh;
}

.pulse-button {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

#VRButton {
  z-index: 99999 !important;
}

.center-align-ver {
  display: flex;
  align-items: center;
}

.circular-button {
  border: none;
  padding: 8px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  border-radius: 50%;
  background: var(--new-ui-blue-button-gradient);
  color: white;
  box-shadow: var(--new-ui-blue-button-gradient) 0 10px 10px -10px;
}

.circular-button:hover {
  transform: scale(1.05);
}

/* CHECKBOX CSS */
.checkbox-wrapper-4 * {
  box-sizing: border-box;
}

.checkbox-wrapper-4 .cbx {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  padding: 4px;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.2s ease;
  display: inline-block;
}

.checkbox-wrapper-4 .cbx:not(:last-child) {
  margin-right: 6px;
}

.checkbox-wrapper-4 .cbx:hover {
  color: var(--new-ui-blue);
}

.checkbox-wrapper-4 .cbx span {
  float: left;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}

.checkbox-wrapper-4 .cbx span:first-child {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  transform: scale(1);
  border: 1px solid #cccfdb;
  transition: all 0.2s ease;
  box-shadow: 0 1px 1px rgba(0, 16, 75, 0.05);
}

.checkbox-wrapper-4 .cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}

.checkbox-wrapper-4 .cbx span:last-child {
  padding-left: 8px;
  line-height: 18px;
}

.checkbox-wrapper-4 .cbx:hover span:first-child {
  border-color: var(--new-ui-blue);
}

.checkbox-wrapper-4 .inp-cbx {
  position: absolute;
  visibility: hidden;
}

.checkbox-wrapper-4 .inp-cbx:checked+.cbx span:first-child {
  color: var(--new-ui-blue);
  animation: wave-4 0.4s ease;
}

.checkbox-wrapper-4 .inp-cbx:checked+.cbx span:first-child svg {
  stroke-dashoffset: 0;
}

.checkbox-wrapper-4 .inline-svg {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}

@media screen and (max-width: 640px) {
  .dna-popup-width {
    max-width: 60%;
  }

  .space-item-responsive {
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 10px;
  }

  .showFormModal2 {
    position: absolute;
    border: 1px solid var(--new-ui-border2);
    background-color: var(--new-ui-black);
    color: white;
    overflow: none;
    border-radius: 8px;
    outline: none;
    padding: 1%;
    top: 5%;
    left: 6%;
    width: 88%;
    max-height: 92%;
    overflow: auto;
    z-index: 110;
    box-shadow: var(--new-ui-black) 0px 6px 17px 3px;
  }

  h1 {
    font-family: "Exo", "fira sans";
    font-size: 24px !important;
  }

  h2 {
    font-family: "Exo", "fira sans";
    font-size: 22px;
  }

  h3 {
    font-family: "Exo", "fira sans";
    font-size: 18px;
  }

  h4 {
    font-family: "Exo", "fira sans";
    font-size: 14px;
    margin: 0;
  }

  html,
  body,
  h5,
  h6 {
    font-family: "fira sans";
  }

  .corousal-banner {
    padding: 16px !important;
  }

  .toolset-banner {
    font-size: 22px !important;
  }

  body {
    font-family: "fira sans";
    font-size: 12px;
  }

  p {
    font-family: "fira sans";
    font-size: 12px !important;
    margin: 0;
  }

  small {
    font-family: "fira sans";
    font-size: 10px;
    margin: 0;
  }

  .checkbox-wrapper-4 .cbx {
    width: 100%;
    display: inline-block;
  }
}

@-moz-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}

@-webkit-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}

@-o-keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}

@keyframes wave-4 {
  50% {
    transform: scale(0.9);
  }
}

.centered-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.bottom-border-active {
  border-bottom: 2px solid var(--dark-blue-2);
}

.bottom-border-disabled {
  border-bottom: 2px solid #ccc;
}

.home-side-button {
  background: var(--new-ui-black-button-gradient);
  box-sizing: border-box;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  line-height: 15px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  word-break: break-word;
  border: 0;
  text-align: left;
}

.home-side-button:hover {
  background: var(--new-ui-blue-button-gradient);
  color: white;
  border-radius: 3px;
  box-shadow: var(--dark-blue-2) 0 10px 5px -10px;
}


.card-gradient {
  background: rgb(3, 75, 175);
  background: linear-gradient(123deg,
      rgba(3, 75, 175, 1) 2%,
      rgba(166, 103, 220, 1) 62%);
  color: white;
}

.white-bg {
  background-color: white;
  color: var(--dark-blue-2);
}

.side-panel-1-btn-pad {
  padding: 7px 0px;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  z-index: 99999;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  border-radius: 50em;
  background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg) no-repeat 50% 50%;
  background-size: contain;
  opacity: 0;
  pointer-events: none;
}

input[type="search"]:focus::-webkit-search-cancel-button {
  opacity: 0.3;
  pointer-events: all;
}

input[type="search"].dark::-webkit-search-cancel-button {
  filter: invert(1);
}

.gret-white {
  color: var(--dark-blue-2);
  background-color: white;
}

.gret-blue {
  color: white;
  background-color: var(--dark-blue-2);
}

.button-shadow-hover:hover {
  box-shadow: var(--dark-blue-2) 0 10px 20px -10px !important;
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.preview-website {
  height: 89vh;
  position: relative;
  inset: 0px;
  overflow: auto;
}

.h1-seo {
  font-size: 15px;
  margin: 0;
  font-family: "fira sans";
}

section::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
}

#section03 a {
  padding-top: 60px;
}

#section03 a span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 46px;
  height: 46px;
  margin-left: -23px;
  border: 4px solid black;
  border-radius: 100%;
  box-sizing: border-box;
}

#section03 a span::after {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "";
  width: 16px;
  height: 16px;
  margin: -12px 0 0 -8px;
  border-left: 4px solid black;
  border-bottom: 4px solid black;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  box-sizing: border-box;
}

#section03 a span::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  content: "";
  width: 44px;
  height: 44px;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  opacity: 0;
  -webkit-animation: sdb03 3s infinite;
  animation: sdb03 3s infinite;
  box-sizing: border-box;
}

#section04 a {
  padding-top: 60px;
}

#section04 a span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 46px;
  height: 46px;
  margin-left: -23px;
  border: 4px solid black;
  border-radius: 100%;
  box-sizing: border-box;
}

#section04 a span::after {
  position: absolute;
  top: 65%;
  left: 50%;
  content: "";
  width: 16px;
  height: 16px;
  margin: -12px 0 0 -8px;
  border-right: 4px solid black;
  border-top: 4px solid black;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  box-sizing: border-box;
}

#section04 a span::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  content: "";
  width: 44px;
  height: 44px;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  opacity: 0;
  -webkit-animation: sdb03 3s infinite;
  animation: sdb03 3s infinite;
  box-sizing: border-box;
}

@-webkit-keyframes sdb03 {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  60% {
    box-shadow: 0 0 0 60px rgba(255, 255, 255, 0.1);
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes sdb03 {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  60% {
    box-shadow: 0 0 0 60px rgba(255, 255, 255, 0.1);
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.demo a {
  position: fixed;
  bottom: 5px;
  right: 40px;
  z-index: 2;
  display: inline-block;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: black;
  letter-spacing: 0.1em;
  text-decoration: none;
  transition: opacity 0.3s;
  opacity: 0.7;
  cursor: pointer;
}

.demo1 a {
  position: fixed;
  top: 15%;
  right: 40px;
  z-index: 2;
  display: inline-block;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: black;
  letter-spacing: 0.1em;
  text-decoration: none;
  transition: opacity 0.3s;
  opacity: 0.7;
  cursor: pointer;
}

.demo1 a:hover {
  opacity: 1;
}

.demo a:hover {
  opacity: 1;
}

.use-formatting {
  white-space: pre-wrap;
}

.calendly-inline-widget {
  height: 92vh !important;
}

.css-13cymwt-control {
  border-radius: 8px !important;
}

.circular-letter {
  width: 30px;
  height: 30px;
  color: white;
  border-radius: 100%;
  padding: 10px;
}

.reward-button {
  background-color: var(--new-ui-black);
  color: white;
  border-radius: 4px;
  border: 1px solid var(--new-ui-border2);
  outline: none;
  width: 100%;
  cursor: pointer;
}

.reward-button:hover {
  border: 1px solid var(--new-ui-blue);
  box-shadow: 0 0 0 0 var(white), 0 0 0 2px var(white);
}

.reward-section {
  background: var(--new-ui-black);
  border: 1px solid var(--new-ui-border);
  color: white;
  border-radius: 4px;
  outline: none;
  width: 100%;
}

.reward-section.active {
  border-radius: 0px 0px 4px 4px;
}

.reward-button.active {
  border-radius: 4px 4px 0px 0px;
}

.off-white-bg {
  color: var(--dark-blue-2);
  background-color: var(--off-white-1);
}

.button-white {
  background-color: var(--new-ui-gray);
  color: white;
  outline: none;
  border: none;
  text-decoration: none;
  cursor: pointer;
}

.button-white:hover {
  color: var(--new-ui-blue);
}

.hover-bg:hover {
  background: var(--off-white-1);
  color: var(--dark-blue-2);
  border-radius: 8px;
}

.overlay {
  position: absolute;
  z-index: 1;
}

.overlay-1 {
  position: absolute;
  z-index: 1;
}

.dark-bg-1 {
  background-color: var(--new-ui-black);
  color: white;
}

.gray-bg {
  background-color: var(--new-ui-gray);
  color: white;
}

.light-bg {
  background-color: white;
  color: var(--new-ui-black)
}

.border-top-4 {
  border-radius: 4px 4px 0px 0px;
}

.border-down-4 {
  border-radius: 0px 0px 4px 4px;
}

.border-all-sides {
  border: 1px solid var(--new-ui-border2);
}

.border-all-sides-blue {
  border: 1px solid var(--new-ui-blue);
}

.w3-padding-bottom {
  padding-bottom: 16px;
}

.w3-padding-top {
  padding-top: 8px;
}

.gret-blue-button {
  border: 1px solid;
  background: var(--new-ui-blue-button-gradient);
  text-decoration: none;
  border-radius: 8px;
  outline: none;
  border: none;
  min-height: 35px;
  color: white;
  padding: 15px 30px 15px 30px;
  cursor: pointer;
}

.gret-blue-publish-button {
  border: 1px solid;
  background: var(--new-ui-blue);
  text-decoration: none;
  border-radius: 8px;
  outline: none;
  border: none;
  min-height: 30px;
  color: white;
  padding: 8px 16px 8px 16px;
  cursor: pointer;
}

.gret-blue-border-button {
  border: 1px solid var(--new-ui-blue);
  /* border-image: linear-gradient(180deg, #1053F3 0%, #06256E 100%);
  border-image-slice: 1; 
  /* background-clip: padding-box;  */
  background: inherit;
  text-decoration: none;
  border-radius: 8px;
  overflow: hidden;
  outline: none;
  min-height: 35px;
  color: white;
  padding: 15px 30px 15px 30px;
  cursor: pointer;
}

.gret-blue-button:disabled {
  background: var(--new-ui-black);
  color: #666666;
}

.gret-black-button {
  border: 1px solid;
  background: var(--new-ui-black-button-gradient);
  text-decoration: none;
  border-radius: 8px;
  outline: none;
  border: none;
  min-height: 35px;
  color: white;
  padding: 15px 30px 15px 30px;
  cursor: pointer;
}

.gret-dotted-black-button {
  border: 1px dashed var(--new-ui-border2);
  background: var(--new-ui-black);
  text-decoration: none;
  border-radius: 8px;
  outline: none;
  min-height: 35px;
  color: white;
  padding: 15px 30px 15px 30px;
  cursor: pointer;
}

.gret-blue-input-button {
  border-radius: 4px;
  border: none;
  background: var(--new-ui-blue-button-gradient);
  padding: 8px 16px;
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.gret-dark-bordered-button {
  border-radius: 4px;
  border: none;
  background: var(--new-ui-black);
  border: 1px solid #26313F;
  padding: 8px 16px;
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.gret-dark-bordered-button:hover {
  border-radius: 4px;
  border: none;
  background: var(--new-ui-black-button-gradient);
  border: 1px solid #26313F;
  padding: 8px 16px;
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.gret-dark-input {
  border-radius: 4px;
  border: 1px solid #26313F;
  background: var(--new-ui-black);
  padding: 8px 16px;
  color: white;
  font-size: 14px;
}

.dark-card {
  border-radius: 4px;
  background: var(--new-ui-black-gradient);
  border-width: 1px;
  border-style: solid;
  border-color: var(--new-ui-border);
  color: white;
  cursor: pointer;
}

.black-card {
  border-radius: 4px;
  background: var(--new-ui-black);
  border-width: 1px;
  border-style: solid;
  border-color: var(--new-ui-border);
  cursor: pointer;
}

.border-down-1 {
  border-bottom: 1px solid var(--new-ui-border2)
}

.border-up-1 {
  border-top: 1px solid var(--new-ui-border2)
}

.hidden {
  display: none !important;
}

.visible {
  display: block !important;
}

.template-card {
  border-radius: 0px 0px 4px 4px;
  background: var(--new-ui-black-gradient);
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: var(--new-ui-border);
  color: white;
}

.template-text-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hover-blue {
  color: white;
}

.hover-blue:hover {
  color: var(--new-ui-blue);
}


.blue-border {
  border: 1px solid var(--new-ui-blue);
}

.buildvr-logo {
  height: 7vh;
}

.switch-menu {
  background-color: inherit;
  color: white;
  border-bottom: 2px solid var(--new-ui-border2);
  cursor: pointer;
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 4px 16px;
}

.switch-menu.active {
  color: var(--new-ui-blue);
  border-bottom: 2px solid var(--new-ui-blue);
}